import React from "react";
import MainTitle from "../Common/MainTitle";
// import BottomBorder from "../../assets/svg/BottamBorder";
import Card from "react-bootstrap/Card";
import Slider from 'react-slick';
import cardImag1 from "../../assets/images/proposals.jpg"
import cardImag2 from "../../assets/images/invoicing.jpg"
import cardImag3 from "../../assets/images/EstimateBanner.jpg"
import cardImag4 from "../../assets/images/Quantity.jpg"

function SectionServiceSlider() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const CardData = [{
    CardImg: cardImag1,
    Title: 'Proposals'
  },
  {
    CardImg: cardImag4,
    Title: 'Quantity Take-Offs'
  },
  {
    CardImg: cardImag2,
    Title: 'Estimates'
  },
  {
    CardImg: cardImag3,
    Title: 'Invoicing'
  } ]

  return (
    <>
      <div className="Section-service-bg py-2 mb-4">
        <div className="container  ">
          <div className="description-section text-center my-5">
            <MainTitle text={"Construction Estimating Services"} className="text-center mt-2" position={'center'} width={'80px'} />
            <p className="description-text">
              At Professional Estimators, we specialize in construction material take-offs and estimating services, offering a comprehensive range of consultancy options. With extensive industry experience and market expertise, we ensure successful bid results for our valued clients across various construction trades. Our portfolio covers a wide spectrum of estimation services, delivered with the quickest turnaround times.
              We employ advanced tools and software such as Planswift, Bluebeam, AutoCAD, MS Office and RS Means to guarantee precision in our estimates and material take-offs. Professional Estimators stands out among other estimation companies for its competitiveness, providing high-quality construction estimates at competitive rates all under one roof.
              For more information or to discuss your estimation needs, please don't hesitate to contact us at (438) 777-6366 or (438) 927-1401 .

            </p>
          </div>
          <MainTitle text={"Expertise IN"} className="text-center mt-4" position={'center'} width={'80px'} />
          <div className="d-flex justify-content-center">
          </div>
        
          <Slider {...settings}>
        {CardData?.map((card, index) => (
          <div className="col-md-4" key={index}>
            <Card className='card-our-expertise'>
              <Card.Img variant="top" src={card?.CardImg} height={250} />
              <Card.Body>
                <Card.Title className='SectionAboutCardTitle'>{card?.Title}</Card.Title>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
        </div>
      </div>
    </>
  );
}

export default SectionServiceSlider;
