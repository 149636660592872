import MainTitle from 'components/Common/MainTitle'
import ReadMoreButton from 'components/Common/ReadMoreButton'
import ServiceCardImg1 from '../../assets/images/MobileSteming.png'
import ServiceCardImg2 from '../../assets/images/MobilePumping.png'
import ServiceCardImg3 from '../../assets/images/MobileAirCompresser.png'
import React from 'react'
import { Link } from "react-router-dom";

function ServiceCardLayout2() {
    const ServiceCardLayoutData =  [ 
        {
            Title: 'Mobile Steaming Unit Services' ,
            content: 'We offer Mobile High Pressure Steaming Unit & Mobile Low Pressure steaming Unit suitable for well tube cleaning , de waxing, line flushing, steam injection in well with end to end service.',
            imgUrl: ServiceCardImg1,
            slug: 'mobile-steaming-unit-services'
        } , 
        {
            Title: 'Mobile Pumping Unit Services' ,
            content: 'We offer Mobile Pumping unit services appropriate for pumping fluid like brine, water, oil effluent, etc. with discharge rate of 1540M3 /Hr & 40M3 /Hr and 200 & 400 KG/CM2 working pressure.',
            imgUrl: ServiceCardImg2,
            slug: 'mobile-pumping-unit-services'

        },
        {
            Title: 'Mobile Air Compressor Services ' ,
            content: 'We provide Mobile Air Compressor services for various oilfield purposes including well offloading, well activation etc. Since 2014, we are providing Mobile Air compressor to top leading companies of oil and gas exploration and production in INDIA.',
            imgUrl: ServiceCardImg3,
            slug: 'mobile-air-compressor-services'
        }
     ]
    return (
        <>
  <div className='container'>
  {
    ServiceCardLayoutData?.map((data, index) => (
      <div className={`row py-4 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`} key={index}>
        <div className='col-md-6'>
        <Link to={`/service/${data?.slug}`}>
          <MainTitle text={data?.Title} position={'start'} width={'80px'}/>
          </Link>
          <p className='text-justify'>
            {data?.content}
          </p>
          <Link to={`/service/${data?.slug}`}>
          <ReadMoreButton/>
            </Link>
        </div>
        <div className='col-md-6'>
          <img src={data?.imgUrl} className='img-fluid' alt='service'/>
        </div>
      </div>
    ))
  }
</div>

        
        
        </>
    )
}

export default ServiceCardLayout2
