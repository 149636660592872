import SectionHeroBanner from 'components/Common/SectionHeroBanner'
import LayoutOne from 'layouts/LayoutOne'
import React from 'react'
import MainTitle from "../components/Common/MainTitle";
const Quantity = () => {
  return (
    < >
  <LayoutOne>
        <SectionHeroBanner title={""} className={"QuantityBg"} />
        <div className="container my-4">
        <MainTitle text={"Quantity Take-Offs"} className="text-center mt-2" position={'center'} width={'80px'} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "2rem",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                listStyleType: "circle",
              }}
            >
              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>We provide quantity take-offs of various materials including but not limited to earthworks, site services, landscaping, concrete, rebar, masonry, millwork, doors, frames & hardware, IMP, ACM Panels, specialties, loading dock equipment, HVAC equipment, electrical equipment, etc.
              </li>

              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              We are comfortable utilizing any take-off software including Bluebeam Revu, Planswift, etc.
              </li>

              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              We can provide the bill of quantities from the performed quantity take-offs presented on our standard template or on the template provided by you.
              </li>

              
            </ul>
          </div>
        </div>
      </LayoutOne>
    </>
  )
}

export default Quantity