import SectionHeroBanner from 'components/Common/SectionHeroBanner'
import LayoutOne from 'layouts/LayoutOne'
import React from 'react'

import MainTitle from "../components/Common/MainTitle";

export const Proposal = () => {
  return (
    < >
  <LayoutOne>
        <SectionHeroBanner title={""} className={"ProposalBg"} />
        <div className="container my-4">
           <MainTitle text={"Proposals"} className="text-center mt-2" position={'center'} width={'80px'} />

          {/* main section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "2rem",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                listStyleType: "circle",
              }}
            >
              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              Whether it is a construction management job, design-bid-build job or design-build job, we can formulate a professional proposal letter for you or your organization to be presented as a competent and reliable firm.            </li>
               <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              Proposal letter can be created on our standard template or a template recommended by the client.
              </li>

              
            </ul>
          </div>
        </div>
      </LayoutOne>
    </>
  )
}
