import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Professionallogo.png";
import EmailIcon from "../assets/images/Icon_Email.png";
import Address from "../assets/images/Icon_Address.png";
import PhoneIcon from "../assets/images/Icon_Phone.png";
const Header = () => {
  
  return (
    <>
      <div className="header_topbar  ">
        <div className="col-md-6  header-logo-margin ">
          <div className="d-flex  align-items-center   ">
            <Link to="/">
              <img src={Logo} alt="" className="img-fluid logoSize" />
            </Link>
            <Link to="/">
              <p className="header-logo-text  mt-1">Professional Estimators</p>
            </Link>
          </div>
        </div>

        <div className="col-md-4 icons-flex ">
          <div className="icons-flex  ">
            <a
              className="rounded-divNav"
              href="mailto:professionalestimators@outlook.com"
             target="_blank"
    rel="noopener noreferrer"
            >
              <img src={EmailIcon} alt="" className="mt-1 rounded-img" />
            </a>
          </div>
          <div className="icons-flex ">
            <a
              className="rounded-divNav"
              href="https://www.google.com/maps?q=1330+SWEETBIRCH+CT.,+MISSISSAUGA,+ON,+CANADA+-+L5C+3R3"
             target="_blank"
    rel="noopener noreferrer"
            >
              <img src={Address} alt="" className="mt-1 rounded-img" />
            </a>
          </div>
          <div className="icons-flex   ">
            <a
              className="rounded-divNav"
              href="tel:+1 (438) 777-6366"
             target="_blank"
    rel="noopener noreferrer"
            >
              <img src={PhoneIcon} alt="" className="mt-1 rounded-img" />
            </a>
          </div>
        </div>
      </div>

    </>
  );
};

export default Header;
