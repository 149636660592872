import LocationLogoContactUs from 'assets/svg/LocationLogoContactUs'
import React from 'react'

function SectionAddress() {

    return (
        <>
            <div className='container' >
                <div className='row py-4'>
                    <div className='contact-address'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <LocationLogoContactUs />
                            <h2 className='gree-color mx-2'>
                                Registered Office
                            </h2>

                        </div>
                        <div className='d-flex justify-content-center mt-2 '>

                            <p className=' contact-us-address fs20px lnh-32 text-center'>
                      1330 SWEETBIRCH CT.,
                      MISSISSAUGA, ON, CANADA - L5C 3R3
                            </p>
                        </div>
                        <div>
                            <p className='gree-color text-center fw600 fs16px'>
                            +1 (438) 777-6366   ||   +1 (438) 924-1401 || professionalestimators@outlook.com
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className='row py-4'>
                    {
                        ContactAddressData?.map((data) => (<div className='col-md-4'>
                            <div className='contact-us-card'>
                                <h1 className='text-center fw600 fs22px primary-color'>
                                    {data?.title}
                                </h1>
                                <p className='text-center fs18px fw500'>
                                    {
                                        data?.address
                                    }
                                </p>
                                <h1 className='text-center fw600 fs16px primary-color'>
                                    {data?.email}
                                </h1>
                            </div>
                        </div>))
                    }

                </div> */}
            </div>

        </>
    )
}

export default SectionAddress
