import React from "react";
import MainTitle from "../Common/MainTitle";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../assets/images/midland.jpg";
import CardImg2 from "../../assets/images/lot_missisaga.png";
import CardImg3 from "../../assets/images/2140_kainsroad.png";
import CardImg4 from "../../assets/images/311_browse.jpg";
import CardImg5 from "../../assets/images/5260_Solar_Dr.jpg";
import CardImg6 from "../../assets/images/1061_Wonderland_Rd.jpg";
import CardImg7 from "../../assets/images/113_155_tyndall.png";
import CardImg8 from "../../assets/images/403_Keele.jpg";
import CardImg9 from "../../assets/images/2500_De.jpeg"
function SectionProject() {
  const CardData = [
    {
      CardImg: CardImg1,
      Title: "1900 Midland Avenue, Scarborough, ON",
      List1:"Area: 2680 SQ. FT (Commercial) ",
      List2:"Detailed Quantity-Take-Off "
    },
    {
      CardImg: CardImg2,
      Title: "LOT 25_Mississauga_RD, ON ",
      List1:"Area: 5000 SQ. FT (Residential) ",
      List2: "Detailed Quantity-Take-Off "
    },
    {
      CardImg: CardImg3,
      Title: "2140 Kains Road, London, ON ",
      List1:"Area: 1150 SQ. FT (Commercial) ",
List2:"Detailed Quantity-Take-Off ",
List3:"Proposal Letter / Contract ",
    },
    {
      CardImg: CardImg4,
      Title: "311 Bowes Road, Concord, ON ",
      List1: "Area: 8000 SQ. FT (Industrial) ",
      List2:"Detailed Quantity-Take-Off ",
      List3:"Proposal Letter / Contract",
      List4 : "Invoice "
    },
    {
      CardImg: CardImg5,
      Title: "5260 Solar Dr, Mississauga, ON ",
      List1:"Area: 1942 SQ. FT (Commercial) ",
      List2:"Detailed Quantity-Take-Off ",
      List3:"Proposal Letter / Contract",
      List4 : "Invoice "
    },
    {
      CardImg: CardImg6,
      Title: "1061 Wonderland Rd. South, London, ON  ",
      List1:"Area: 2000 SQ. FT (Commercial - Restoration) ",
      List2:"Detailed Quantity-Take-Off "
    },
    {
      CardImg: CardImg7,
      Title: "113 & 115 Tyndall Ave, Toronto, ON",
      List1:"Area: 5000 SQ. FT (Residential-Garage Restoration) ",
      List2:"Detailed Quantity-Take-Off "
    },
     {
      CardImg: CardImg8,
      Title: "403 Keele St, Toronto, ON",
      List1:"Area: 23,000 SQ. FT (Commercial)",
      List2:"Detailed Quantity-Take-Off ",
      List3: "Pipe Insulation ",
      List4:"Firestopping"
    },
    {
     CardImg: CardImg9,
     Title: "5200 DE LA Savane Tours A & B, Montreal, QC ",
     List1:"Area: 15,000 SQ. FT (Commercial) ",
     List2:"Detailed Quantity-Take-Off "
   },
  ];
  return (
    <>
      <div className="container mt-5">
        <MainTitle
          text={"Our Projects"}
          className={"text-center "}
          width={"119px"}
          position={"center"}
        />

        <div className="grid row   py-4">
          {CardData &&
            CardData?.map((card, index) => (
              <div className="col-md-4 project-card" key={index}>
                <Card className="projectCard1">
                  <Card.Img variant="top" src={card?.CardImg} className="projectCard" />
                  <Card.Body>
                    <Card.Title className="SectionAboutCardTitle">
                      {card?.Title}
                    </Card.Title>
                    <ul
              style={{
                display: "flex",
                flexDirection: "column",
                // gap: "1rem",
                listStyleType: "circle",
                padding:"0px 7px"              }}
            >
              <li >
            {card?.List1 ?? ""}
              </li>
              <li>
              {card?.List2 ?? ""}
              </li>
              {card?.List3 &&<li>
              {card?.List3 ?? ""}
              </li>}
              {card?.List4  && <li>
              {card?.List4 ?? ""}
              </li>}
            </ul>
                 
                  </Card.Body>
                </Card>

              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default SectionProject;
