import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home"; 
import Services from "./pages/Services"; 
import Contact from "./pages/Contact"; 
import SingleService from "./pages/SingleService"; 
import Team from "./pages/Team";   
import NotFound from "./pages/NotFound"; 
import Project from "./pages/Project";
import { Proposal } from "pages/Proposal";
import Quantity from "pages/Quantity";
import Estimates from "pages/Estimates";
import Invoicing from "pages/Invoicing";

function App() {
  return (
    <>
    <Router>
      <ScrollToTop>
        <Switch>
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          /> */}
          <Route exact path={"/"} component={Home} />
          {/* <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          /> */}
          <Route
            path="/home"
            component={Home}
          />
           {/* <Route
            path="/careers"
            component={Careers}
          />
          <Route
            path="/about"
            component={About}
          /> */}
          <Route
            path="/proposals"
            component={Proposal}
          />
          <Route
            path="/quantity"
            component={Quantity}
          />
          <Route
            path="/estimates"
            component={Estimates}
          />
          <Route
            path="/invoicing"
            component={Invoicing}
          />
          <Route
            path="/services"
            component={Services}
          />
          {/* <Route
            path="/service/:name"
            component={ServiceInnerPage}
          />
          <Route
            path="/features-details/:name"
            component={FeaturesDetails}
          />
           <Route
            path="/policy-details/:name"
            component={PolicyDetails}
          />
            <Route
            path="/profile-details/:id"
            component={ProfileDetailsPage}
          />
          <Route
            path="/gallery"
            component={Gallery}
          />
          <Route
            path="/gallery-two"
            component={GalleryTwo}
          /> */}
          <Route
            path="/contact"
            component={Contact}
          />
          {/* <Route
            path="/single-services"
            component={SingleService}
          /> */}
          <Route
            path="/team"
            component={Team}
          />
          {/* <Route
            path= "/shop"
            component={Shop}
          />
          <Route
            path="/single-shop"
            component={SingleShop}
          />
          <Route
            path="/blog"
            component={BlogPageOne}
          />
          <Route
            path="/blog-two"
            component={BlogPageTwo}
          />
          <Route
            path="/single-blog"
            component={SingleBlog}
          /> */}
          <Route
            path="/project"
            component={Project}
          />
          <Route
            path= "/single-blog"
            component={SingleService}
          />
         
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
    {/* <SectionEnquiryButton/> */}
    </>

  );
}

export default App;
