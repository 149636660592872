import React from "react";
import { Link } from "react-router-dom";
// import Logo from "../assets/images/South-Asia-Logo.png";
import LocationLogo from "../assets/svg/LocationLogo";
import PhoneLogo from "../assets/svg/PhoneLogo";
import InstagramLogo from "../assets/svg/InstagramLogo";
import FaceBookLogo from "../assets/svg/FaceBookLogo";
import TwiiterLogo from "../assets/svg/TwiiterLogo";
import LinkedinLogo from "../assets/svg/LinkedinLogo";
import Logo from "../assets/images/Professionallogo.png"
const Footer = () => {

  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="widget">
                <div className="footer_logo d-flex align-items-center">
                  <Link to="/" className="text-white">
                    <img className="img-responsive" src={Logo} alt="" width={50} height={50}/>
                    <span className="mx-2 fs20px">Professional Estimators</span>
                  </Link>
                </div>
                <div className="footer_p">
                  <p className="footer_para text-white">
                    <Link to="/estimates" className="text-white">
                    At Professional Estimators, we specialize in construction material take-offs and estimating services, offering a comprehensive range of consultancy options.
                    </Link>
                  </p>
                </div>
                <div className="footer_socil">
                  <ul className="list-icons link-list footer_soc">
                    <li>
                      <a
                        href="https://www.instagram.com/professionalestimators/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramLogo />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/people/Professional-Estimators/61555970131118/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaceBookLogo />
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwiiterLogo />
                      </a>
                    </li>

                    <li>
                      <a
                        href="http://www.linkedin.com/in/professional-estimators"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedinLogo />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <ul className="list-unstyled">
                <h1 className="footer-heading">Contact Info</h1>
                <li className="d-flex gap-1 mb-2">
                  <div className="mt-2">
                    <a
                      href={`https://www.google.com/maps?q=1330+SWEETBIRCH+CT.,+MISSISSAUGA,+ON,+CANADA+-+L5C+3R3`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LocationLogo />
                    </a>
                  </div>
                  <div className="mb-2 mt-2 mx-2">
                    <a
                      href={`https://www.google.com/maps?q=1330+SWEETBIRCH+CT.,+MISSISSAUGA,+ON,+CANADA+-+L5C+3R3`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      1330 SWEETBIRCH CT.,
                      MISSISSAUGA, ON, CANADA - L5C 3R3

                    </a>
                  </div>
                </li>
                <li className="d-flex gap-1 mb-2">
                  <div>
                    <PhoneLogo />
                  </div>
                  <div className="mx-2">
                    <a href="tel:+1 (438) 777-6366" className="text-white">
                      +1 (438) 777-6366
                    </a>
                  </div>
                </li>
                <li className="d-flex gap-1 mb-2">
                  <div>
                    <PhoneLogo />
                  </div>
                  <div className="mb-2 mx-2">
                    <a
                      href="tel:+1 (438) 924-1401"
                      className="text-white"
                    >
                      +1 (438) 924-1401
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-2  col-md-2 col-sm-12 ">
              <h1 className="footer-heading">Company</h1>
              <ul>
                <li>
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/estimates" className="text-white">
                    What do we offer
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="text-white">
                   Team
                  </Link>
                </li>
                <li>
                  <Link to="/project" className="text-white">
                  Project
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-white">
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-2">
              <img src={IFDCLogo} className="img-fluid py-2" alt="IFDC Logo" style={{ width: '100px' }} />
              <img src={IADCLogo} className="img-fluid py-2" alt="IADC Logo" style={{ width: '100px' }} />
            </div> */}


          </div>
        </div>
        
        <div className="subFooterSouth py-4">
          <p className="text-center">
          WE OFFER SERVICES THROUGHOUT ONTARIO
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
