import React from "react";
import MainTitle from "components/Common/MainTitle";
function SectionMap() {
  // const location = "1330 SWEETBIRCH CT., MISSISSAUGA, ON, CANADA - L5C 3R3";
  const googleMapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.8794441314424!2d-79.66129328451098!3d43.56169477912588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b452f8b792a29%3A0x5e5f2ab4af47c0d8!2s1330%20Sweetbirch%20Ct%2C%20Mississauga%2C%20ON%20L5C%203R3%2C%20Canada!5e0!3m2!1sen!2sus!4v1706520416905!5m2!1sen!2sus`;

  return (
    <>
      <div className="container my-5 ContactMain ">
  
        <div className="row">
        
          <div className="col-md-5 p-0">
            <div className="d-flex justify-content-end">
              {/* <iframe
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5028.518965560287!2d72.5806217579987!3d23.114963055720768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83ac30f361e5%3A0x27853233a2e5cb72!2sSouth%20Asia%20Consultancy!5e0!3m2!1sen!2sin!4v1706520416905!5m2!1sen!2sin"
                src="https://maps.app.goo.gl/fiU5QCqWEFeYz4LJ9?g_st=com.google.maps.preview.copy"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="googleMap"
                style={{
                  width: "526px",
                  height: "628px",
                }}
              > */}
 <iframe
      src={googleMapUrl}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
      style={{
        width: "526px",
        height: "628px",
      }}
    >
              </iframe>
            </div>
          </div>
          <div className="col-md-7 ">
          <MainTitle text={'Get in touch '} className="my-4 mt-5"/>
            <div>
              <form>
                {/* <div className="my-4">
               <input
                  type="text"
                  placeholder="First Name"
                  className="CustomInputContactInput mr-2 p-2"
                />
              
               <input
                  type="text"
                  placeholder="Last Name"
                  className="CustomInputContactInput p-2"
                />
                </div>
             
                <div  className="my-2">
               <input
                  type="text"
                  placeholder="Phone"
                  className="CustomInputContactInput  mr-2 p-2"
                />
              
               <input
                  type="text"
                  placeholder="Email"
                  className="CustomInputContactInput p-2"
                />
                </div>
                <input
                  type="text"
                  placeholder="Your Message Here"
                  className="CustomInputContactTextArea p-2 my-2 mb-4"
                /> */}
                <div className="">
                  <button className=" btn   ContactPageBtn  my-4" onClick={() => {
  window.open("https://docs.google.com/forms/d/1Bp4JN72c7Li6DpByjCaEXxb49TeVlWfEjcNM3DQm7MM/viewform?edit_requested=true", "_blank");
}}>
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionMap;
