import React from "react";
import LayoutOne from "layouts/LayoutOne";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
import MainTitle from "../components/Common/MainTitle";


const Invoicing = () => {
  return (
    <>
      <LayoutOne>
        <SectionHeroBanner title={""} className={"invoicingBg"} />
        <div className="container my-4" >
        <MainTitle text={"Invoicing"} className="text-center mt-2" position={'center'} width={'80px'} />

          {/* main section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom:"2rem"
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                listStyleType: "circle",
              }}
            >
              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              We can provide invoicing services including monthly draws to present to client with accurate monthly progression of the work.
              </li>

              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              comprehensive solution for businesses and freelancers to manage their billing process efficiently and effectively.	
              </li>

             
            </ul>
          </div>
        </div>
      </LayoutOne>
    </>
  );
};

export default Invoicing;
