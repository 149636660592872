import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/Professionallogo.png";

const Menus = () => {
  const [showOffer, setShowOffer] = useState(false);

  const handleOfferClick = () => {
    setShowOffer(!showOffer);
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {isMobile ? (
        <>
          <div className="menu_area">
            <div className="navigation">
              <div className="container">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={Logo}
                      alt="logo"
                      className="img-fluid Mobile-logo "
                      style={{ width: "48px", height: "49px" }}
                    />
                  </Link>
                </div>
                <p className="fs14px Mobile-Heading primary-color fw500">
                   Professional Estimators{" "}
                </p>
                <MobileBtn />
                <MobileMenu />

              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="nav-south-asia  menu-south-asia d-flex align-items-center">
            <div className="container ">
              <div className="nav-south-asia">
                <Navbar>
                  <div className="nav-south-asia menu-south-asia d-flex align-items-center">
                    <div className="container">
                      <Navbar>
                        <ul className="navbar" style={{ color: "white", fontSize: "1.1rem" }}>
                          <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                          </li>
                          <div className="dropdown">
                            <button className="dropbtn">What do we offer</button>

                            <div className="dropdown-content">
                              <Link to="/proposals">Proposals</Link>
                              <Link to="/quantity">	Quantity Take-Offs</Link>
                              <Link to="/estimates">Estimates
                              </Link>
                              <Link to="/invoicing">Invoicing</Link>
                            </div>
                          </div>
                          <li className="nav-item">
                            <Link className="nav-link" to="/team">Team</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/project">Project</Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact us</Link>
                          </li>
                        </ul>
                      </Navbar>
                    </div>
                  </div>
                </Navbar>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Menus;
