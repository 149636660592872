import React from "react";

function RightArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      fill="none"
      viewBox="0 0 11 10"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.182 1L10 5m0 0L6.182 9M10 5H1"
      ></path>
    </svg>
  );
}

export default RightArrow;