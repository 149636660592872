import React from "react";

function LinkedinLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#fff"
        d="M35.493 12.27c3.046 9.31-2.074 19.337-11.382 22.341C14.802 37.658 4.774 32.538 1.77 23.23-1.234 13.921 3.843 3.893 13.152.89a17.41 17.41 0 015.5-.889c7.659.042 14.429 4.993 16.84 12.27zM18.61 1.608c-8.928 0-16.12 7.235-16.12 16.163s7.235 16.12 16.163 16.12c8.927 0 16.12-7.234 16.12-16.162 0-1.693-.254-3.343-.804-4.95A16.145 16.145 0 0018.61 1.607z"
      ></path>
      <path
        fill="#fff"
        d="M11.967 14.682h3.385V25.6h-3.385V14.682zm1.693-5.416c1.1 0 1.946.889 1.946 1.989s-.889 1.946-1.989 1.946-1.946-.888-1.946-1.946c0-1.143.889-1.989 1.989-1.989zM17.425 14.682h3.258v1.481a3.6 3.6 0 013.216-1.777c3.427 0 4.062 2.243 4.062 5.204v5.966h-3.3v-5.289c0-1.269 0-2.877-1.777-2.877s-2.031 1.396-2.031 2.75v5.416h-3.428V14.682z"
      ></path>
    </svg>
  );
}

export default LinkedinLogo;