import React from "react";

function PhoneLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#F2F2F5"
        d="M16.866 13.009l-3.798-1.595a.83.83 0 00-.95.23l-1.681 2.013a12.446 12.446 0 01-6.008-5.888L6.483 6.12a.78.78 0 00.234-.93L5.09 1.468a.81.81 0 00-.382-.396.834.834 0 00-.55-.066L.63 1.804c-.18.04-.34.14-.454.28A.788.788 0 000 2.582C0 11.105 7.05 18 15.734 18c.184 0 .363-.06.507-.173a.796.796 0 00.286-.445l.814-3.456a.793.793 0 00-.07-.542.817.817 0 00-.405-.375z"
      ></path>
    </svg>
  );
}

export default PhoneLogo;