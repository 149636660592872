import SectionHeroBanner from "components/Common/SectionHeroBanner";
import LayoutOne from "layouts/LayoutOne";
import React from "react";
import MainTitle from "../components/Common/MainTitle";
// import EstimateBanner from "../assets/images/"
const Estimates = () => {
  return (
    <>
      <LayoutOne>
        <SectionHeroBanner title={""} className={"estimateBg"} />
        <div className="container my-4">
        <MainTitle text={"Estimates"} className="text-center mt-2" position={'center'} width={'80px'} />

          {/* main section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "2rem",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                listStyleType: "circle",
              }}
            >
              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              We can prepare a budget estimate for you broken down in the division as per CSI master format. 
              </li>

              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              We have a working knowledge of Uniformat as well, thus can be facilitated if requested. 
              </li>

              <li style={{ fontSize: "1.1rem", justifyContent: "start" }}>
              Being in the Canadian construction industry for many years, we have established good relations with numerous trades which would benefit on preparing an accurate budget estimate for you.  
              </li>

            </ul>
          </div>
        </div>
      </LayoutOne>
    </>
  );
};

export default Estimates;
