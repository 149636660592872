import LeftRedArrow from 'assets/svg/LeftRedArrow'
import React from 'react'

function ReadMoreButton() {
    return (
        <>
          <div>
            <span className="s-primary d-flex align-items-center ">
              Read More <LeftRedArrow />
            </span>
          </div>
        
        </>
    )
}

export default ReadMoreButton
