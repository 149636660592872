
import React from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import "./Team.css"; // Import the CSS file
import temMember1 from "../assets/images/Bhargav.png"
import temMember2 from "../assets/images/HARDIK_GONDALIYA_IMAGE.jpg"
import MainTitle from "components/Common/MainTitle";
import { useMediaQuery } from "react-responsive"
const Team = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <MetaTags>
        <title>ProfessionalEstimatore | Team</title>
        <meta name="description" content="Organic Food React JS Template." />
      </MetaTags>
      <LayoutOne>
        <div className="team-page">
          <section className="team_section">
            <div className="container">
            <MainTitle
          text={"Our Team"}
          className={"text-center "}
          width={"119px"}
          position={"center"}
        />

              <div className="teamContainer">
                {/* first member */}
                <div className="team-member">
                  <div className="team-img-wrapper col-md-5">
                    <img
                      src={temMember1}
                      alt="Team Member1"
                      height={350}
                      weight={350}
                    />
                  </div>
                  <div className="team-member-info">
                    <ul>
                      <li>Education: M.Eng. in Construction Engineering and Management</li>
                      <li>Experience: Bhargav has over 4 years of experience looking after various construction projects including residential and ICI with sizing from 5,000 SF to 1M SF. </li>
                    </ul>
                  </div>
                </div>

                {/* second member */}

               {isMobile ? <><div className="team-member">
                <div className="team-img-wrapper">
                    <img
                      src={temMember2}
                      alt="Team Member"
                      height={350}
                      weigth={350}
                    />
                  </div>
               
                  <div className="team-member-info">
                    <ul>
                      <li>Education: M.Eng. in Construction Engineering and Management</li>
                      <li>Experience: : Hardik has over 4 years of experience performing quantity take-offs and preparing estimates of construction projects with pricing ranging from $100k to $50M.</li>
                    </ul>
                  </div>
                 
                </div></> :<div className="team-member">
                  <div className="team-member-info">
                    <ul>
                      <li>Education: M.Eng. in Construction Engineering and Management</li>
                      <li>Experience: : Hardik has over 4 years of experience performing quantity take-offs and preparing estimates of construction projects with pricing ranging from $100k to $50M.</li>
                    </ul>
                  </div>
                  <div className="team-img-wrapper col-md-5">
                    <img
                      src={temMember2}
                      alt="Team Member"
                      height={350}
                      weigth={350}
                    />
                  </div>
                </div>}
              </div>
              {/* <div className="row">{teamlistmap}</div> */}
            </div>
          </section>
        </div>
      </LayoutOne>
    </>
  );
};

export default Team;
