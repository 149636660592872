import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EmailIcon from "../../assets/images/Icon_Email.png";
import Address from "../../assets/images/Icon_Address.png";
import PhoneIcon from "../../assets/images/Icon_Phone.png";
import CloseIcon from "../../assets/images/closeIcon.png";

const MobileMenu = () => {
  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(`.subMenu`);
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        `<span class="menuExpand"><i></i></span>`
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menuExpand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", e => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvasMobileMenu" id="offcanvas-mobile-menu">
      <button
        className="offcanvasMenuClose"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        {/* <i className="icon-glyph-146"></i> */}
        {/* <i class="fa fa-close"></i> */}
        <img src={CloseIcon} className="img-fluid" alt="img" />
      </button>

      <div className="offcanvasWrapper">
        <div className="offcanvasInnerContent">

          <nav className="offcanvasNavigation" id="offcanvas-navigation">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">
                  What we do offer </Link>
              </li>
              <li>
                <Link to="/proposals">
                  Proposals</Link>
              </li>
              <li> <Link to="/quantity">	Quantity Take-Offs</Link></li>
              <li>  <Link to="/estimates">Estimates
              </Link></li>
              <li><Link to="/invoicing">Invoicing</Link></li>
              <li>
                <Link to="/team">
                  Team

                </Link>
              </li>

              <li>
                <Link to="/project">
                  Project

                </Link>
              </li>
              <li>
                <Link to="/contact">
                  Contact us
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header_top_right list-unstyled">
            <ul>
              <li className="d-flex align-items-center ">
                <img src={PhoneIcon} alt="" className="img-fluid" />
                <a href="tel:+1 (438) 777-6366" className="mx-2">+1 (438) 777-6366</a>
              </li>
              <li className="d-flex align-items-center ">
                <img src={EmailIcon} alt="" className="img-fluid" />
                <a href="mailto:professionalestimators@outlook.com" className="mx-2">
                  professionalestimators@outlook.com
                </a>
              </li>
              <li className="d-flex align-items-center ">
                <img src={Address} alt="" className="img-fluid" />
                <a
                  href={`https://www.google.com/maps?q=402,1330 SWEETBIRCH CT., MISSISSAUGA, ON - L5C 3R3`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 text-justify"
                >
                  1330 SWEETBIRCH CT., MISSISSAUGA, ON - L5C 3R3
                </a>



              </li>
            </ul>
          </div>



        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
