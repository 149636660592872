import React from 'react'
import LayoutOne from '../layouts/LayoutOne'
import SectionProject from 'components/About/SectionProject'

function Project() {
    return (
       <>
           <LayoutOne>
           <SectionProject />
           </LayoutOne>
       </> 
    )
}

export default Project
